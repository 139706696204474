import React from "react";

import { Link, navigate } from "gatsby";

import GrinLogo from "../../../scss/images/logos/grin-logo.svg";

const RevcoreIndexPageHero = () => {
  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <section class="has-background-white" style={{ padding_bottom: "140px" }}>
      <div class="section pb-6 has-background-dark">
        <div class="container is-relative">
          <div class="columns is-multiline is-centered">
            <div class="column is-8 mb-4 has-text-centered">
              <h2 class="has-text-white mt-2 mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">
                Believe in Development, not hype.
              </h2>
              <p class="subtitle has-text-grey has-text-grey-light mb-5">
                Revcore is a development services company dedicated to
                no-nonsense technological advancement in security, privacy, and
                cryptocurrency-related fields.
              </p>
              <p class="subtitle has-text-grey has-text-grey-light mb-1">
                We're small but growing, and currently focused on the{" "}
                <a href="https://github.com/mimblewimble/grin">
                  Grin Cryptocurrency Project
                </a>
                . Check out our suite of{" "}
                <a href="https://grintools.revcore.net">Grin-related tools</a>,
                or have a look at our{" "}
                <Link to="/services">development services</Link>.
              </p>
            </div>
          </div>
          <div class="columns is-multiline" style={{ margin_bottom: "-150px" }}>
            <div class="column is-6 mb-4">
              <div
                class="box p-6 has-background-light has-text-centered"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  navTo(e, "https://grintools.revcore.net");
                }}
              >
                <span class="mb-3 is-inline-block">
                  <GrinLogo style={{ width: "100px", height: "100px" }} />
                </span>
                <h4 class="is-size-4 has-text-weight-bold">Grin Tools</h4>
                <p>Dedicated to supporting and growing Grin's Infrastructure</p>
              </div>
            </div>
            <div class="column is-6 mb-4">
              <div
                class="box p-6 has-background-light is-covered has-text-centered"
                style={{ height: "100%", cursor: "pointer" }}
                onClick={(e) => {
                  navTo(e, "/services");
                }}
              >
                <span class="mb-3 is-inline-block">
                  <svg
                    class="mx-auto"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <path
                      d="M25.6 22.9C25.7 23 25.8 23 26 23H33C33.6 23 34 22.6 34 22C34 21.8 34 21.7 33.9 21.6L30.4 14.6C30.1 14.1 29.5 13.9 29 14.2C28.9 14.3 28.7 14.4 28.6 14.6L25.1 21.6C24.9 22 25.1 22.6 25.6 22.9ZM29.5 17.2L31.4 21H27.6L29.5 17.2ZM18.5 14C16 14 14 16 14 18.5C14 21 16 23 18.5 23C21 23 23 21 23 18.5C23 16 21 14 18.5 14ZM18.5 21C17.1 21 16 19.9 16 18.5C16 17.1 17.1 16 18.5 16C19.9 16 21 17.1 21 18.5C21 19.9 19.9 21 18.5 21ZM22.7 25.3C22.3 24.9 21.7 24.9 21.3 25.3L18.5 28.1L15.7 25.3C15.3 24.9 14.7 24.9 14.3 25.3C13.9 25.7 13.9 26.3 14.3 26.7L17.1 29.5L14.3 32.3C13.9 32.7 13.9 33.3 14.3 33.7C14.7 34.1 15.3 34.1 15.7 33.7L18.5 30.9L21.3 33.7C21.7 34.1 22.3 34.1 22.7 33.7C23.1 33.3 23.1 32.7 22.7 32.3L19.9 29.5L22.7 26.7C23.1 26.3 23.1 25.7 22.7 25.3ZM33 25H26C25.4 25 25 25.4 25 26V33C25 33.6 25.4 34 26 34H33C33.6 34 34 33.6 34 33V26C34 25.4 33.6 25 33 25ZM32 32H27V27H32V32Z"
                      fill="#00d1b2"
                    ></path>
                    <circle cx="24" cy="24" r="23.5" stroke="#00d1b2"></circle>
                  </svg>
                </span>
                <h4 class="is-size-4 has-text-weight-bold">
                  Development Services
                </h4>
                <p>No-nonsense development.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevcoreIndexPageHero;
