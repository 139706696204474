import * as React from "react";
import "../scss/bulma-theme.scss";

import Layout from "../components/common/layout";
import RevcoreIndexPageHero from "../components/frontend/indexpage/hero-revcore"

const IndexPage = () => {
  return (
    <Layout>
      <RevcoreIndexPageHero/>
    </Layout>
  );
};

export default IndexPage;
